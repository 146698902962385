import { Container, Grid } from '@material-ui/core'
import React from 'react'
import Slider from 'react-slick';
import Layout from '../../components/layout'
import GridRow from '../../components/_grid-row'


import pageStyles from '../../assets/scss/class-internal.module.scss'
import khadigaImg from '../../images/team/khadiga.jpg';

export default function Khadiga() {
    const sliderImages = [
        khadigaImg
    ];

    const settings = {
        dots: true,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 3.6,
        slidesToScroll: 3.6,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2.05,
                    slidesToScroll: 2.05,
                    infinite: false,
                    dots: true
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1.02,
                    slidesToScroll: 1.02,
                    infinite: false,
                    dots: true
                }
            },
        ]
    };

    return (
        <React.Fragment>
            <Layout pageName="Meet the Team" backLink="/team">
                <div className={["class-internal", pageStyles.wrapper].join(" ")}>
                    <Container maxWidth="xl">
                        <GridRow>
                            {/* Slider section */}
                            <Grid container spacing={3} justify="center">
                                <Grid item xs={12}>
                                    {sliderImages?
                                    <Slider  {...settings}>
                                        {
                                            sliderImages.map(img => {
                                                return (
                                                    <div>
                                                        <img src={img}/>
                                                    </div>
                                                )
                                            })
                                        }

                                    </Slider>
                                    :
                                    ""

                                    }
                                </Grid>
                                {/* Content section */}
                                <Grid item xs={12} lg={8}>
                                    <div className={pageStyles.header}>
                                        <h2 className="h2">Khadiga Amin</h2>
                                    </div>
                                    <div className={pageStyles.desc}>
                                        <p>
                                            Egypt's first-ever Ironman finisher and now nationally accredited Triathlon Coach.
                                            <br /><br />
                                            To inquire or book, please send an email to info@beachmonkeyz.com.
                                        </p>
                                    </div>
                                    <Grid item xs={12} md={6} lg={5}>
                                        <div className={pageStyles.reserveButton}>
                                            <a href="mailto:info@beachmonkeyz.com" className={pageStyles.emailLink}>
                                                Send us an e-mail
                                            </a>
                                        </div>
                                    </Grid> 
                                </Grid>
                            </Grid>
                        </GridRow>
                    </Container>
                </div>
            </Layout>
        </React.Fragment>
    )
}
